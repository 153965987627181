import {all, call, fork, put, takeEvery, takeLatest} from "redux-saga/effects";
import axios from 'axios';
import * as ActionTypes from '../../constants/ActionTypes';
import { fetchError } from "../actions/Common";
import { fetchGymsSuccess, addGymSuccess, addGymError, editGymSuccess, editGymError, updateGymSuccess, updateGymError, deleteGymSuccess, deleteGymError, updateLocationSuccess, updateLocationError, deleteLocationSuccess, deleteLocationError, fetchLocationsSuccess, fetchLocationsError, fetchCountriesSuccess, getOSStokenSuccess } from '../actions/Gyms';

function* addGymRequest({ gym }) {
  console.log('Add Gym called');
  try {
    const resp = yield call(axios.post, '/v1/admin/add_premise', gym);
    yield put(addGymSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(addGymError(error.response));
  }
}

function* editGymRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v1/admin/get_premise', payload);
    yield put(editGymSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(editGymError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* updateGymRequest({ payload }) {
  console.log('Update Gym called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/update_premise', payload);
    console.log(resp);
    yield put(updateGymSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(updateGymError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* deleteGymRequest({ payload }) {
  console.log('Delete Gym called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/delete_premise', payload);
    console.log(resp);
    yield put(deleteGymSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(deleteGymError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* fetchGymsRequest() {
  try {
    const resp = yield call(axios.post, '/v1/admin/premises');
    yield put(fetchGymsSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(fetchError(error.response));
  }
}

function* updateLocationRequest({ payload }) {
  console.log('Update Location called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/update_location', payload);
    console.log(resp);
    yield put(updateLocationSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(updateLocationError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* deleteLocationRequest({ payload }) {
  console.log('Delete Location called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/delete_location', payload);
    console.log(resp);
    yield put(deleteLocationSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(deleteLocationError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* fetchLocationsRequest() {
  try {
    const resp = yield call(axios.post, '/v1/admin/locations');
    yield put(fetchLocationsSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(fetchError(error.response));
  }
}

function* fetchCountriesRequest() {
  try {
    const resp = yield call(axios.get, '/v1/countries');
    console.log(resp.data);
    yield put(fetchCountriesSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(fetchError(error.response));
  }
}

// function* getOSStokenRequest() {
//   try {
//     const resp = yield call(axios.post, '/v1/admin/request_oss_access_token');
//     console.log(resp);
//     yield put(getOSStokenSuccess(resp.data));
//   } catch (error) {
//     console.log(error.response);
//     yield put(fetchError(error.response));
//   }
// }

// export function* getOSStoken() {
//   yield takeLatest(ActionTypes.GET_OSS_TOKEN, getOSStokenRequest);
// }

export function* fetchGyms() {
  yield takeLatest(ActionTypes.FETCH_GYMS, fetchGymsRequest);
}

export function* addGym() {
  yield takeLatest(ActionTypes.ADD_GYM, addGymRequest);
}

export function* editGym() {
  yield takeLatest(ActionTypes.EDIT_GYM, editGymRequest);
}

export function* updateGym() {
  yield takeLatest(ActionTypes.UPDATE_GYM, updateGymRequest);
}

export function* deleteGym() {
  yield takeLatest(ActionTypes.DELETE_GYM, deleteGymRequest);
}

export function* fetchLocations() {
  yield takeLatest(ActionTypes.FETCH_LOCATIONS, fetchLocationsRequest);
}

export function* updateLocation() {
  yield takeLatest(ActionTypes.UPDATE_LOCATION, updateLocationRequest);
}

export function* deleteLocation() {
  yield takeLatest(ActionTypes.DELETE_LOCATION, deleteLocationRequest);
}

export function* fetchCountries() {
  yield takeLatest(ActionTypes.FETCH_COUNTRIES, fetchCountriesRequest);
}

export default function* rootSaga() {
  yield all([
    fork(fetchGyms),
    fork(addGym),
    fork(editGym),
    fork(updateGym),
    fork(deleteGym),
    fork(fetchLocations),
    fork(updateLocation),
    fork(deleteLocation),
    fork(fetchCountries),
    // fork(getOSStoken)
  ]);
}
